// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/WWKucZsUu";
import PlatformIcon from "./faN27UAr2";
const PlatformIconFonts = getFonts(PlatformIcon);

const serializationHash = "framer-KSBw7"

const variantClassNames = {ihOEg33i9: "framer-v-uonug9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, platforms, width, ...props}) => { return {...props, JQQ5eRn0K: icon ?? props.JQQ5eRn0K, nzWVBfMQK: platforms ?? props.nzWVBfMQK ?? "LinkedIn"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;platforms?: string;icon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nzWVBfMQK, JQQ5eRn0K, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ihOEg33i9", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uonug9", className, classNames)} data-framer-name={"LinkedIn"} layoutDependency={layoutDependency} layoutId={"ihOEg33i9"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider height={32} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 17.5) - 0) - 32) / 2)))}><motion.div className={"framer-1qmucvp-container"} layoutDependency={layoutDependency} layoutId={"h8v1iIUlO-container"}><PlatformIcon height={"100%"} id={"h8v1iIUlO"} JvL7R8BWe={toResponsiveImage(JQQ5eRn0K)} layoutId={"h8v1iIUlO"} variant={"r2DNyuDzP"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1ipwkrm"} data-styles-preset={"WWKucZsUu"}>Platform</motion.p></React.Fragment>} className={"framer-h5fvu2"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"gFuzbWPdo"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={nzWVBfMQK} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KSBw7.framer-3hblth, .framer-KSBw7 .framer-3hblth { display: block; }", ".framer-KSBw7.framer-uonug9 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-KSBw7 .framer-1qmucvp-container { flex: none; height: auto; position: relative; width: auto; }", ".framer-KSBw7 .framer-h5fvu2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KSBw7.framer-uonug9 { gap: 0px; } .framer-KSBw7.framer-uonug9 > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-KSBw7.framer-uonug9 > :first-child { margin-left: 0px; } .framer-KSBw7.framer-uonug9 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 17.5
 * @framerIntrinsicWidth 64.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"nzWVBfMQK":"platforms","JQQ5eRn0K":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerYGYPSTZr4: React.ComponentType<Props> = withCSS(Component, css, "framer-KSBw7") as typeof Component;
export default FramerYGYPSTZr4;

FramerYGYPSTZr4.displayName = "Icon/Platforms icons";

FramerYGYPSTZr4.defaultProps = {height: 17.5, width: 64.5};

addPropertyControls(FramerYGYPSTZr4, {nzWVBfMQK: {defaultValue: "LinkedIn", displayTextArea: true, title: "Platforms", type: ControlType.String}, JQQ5eRn0K: {title: "Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerYGYPSTZr4, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PlatformIconFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})